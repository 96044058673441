import './Dropdown.css'

const Dropdown = ( { options, selectedIdx, onChange, ...props }) => {

  const getOptionText = opt => typeof opt === 'string' ? opt : opt.label

  const optionsElements = options instanceof Array
    ? options.map((opt, idx) => {
      const txt = getOptionText(opt)
      return <div key={txt} className="dropdownEl" onClick={() => onChange(idx)}>{txt}</div>
    })
    : []

  const idx = selectedIdx === undefined ? 0 : selectedIdx
  const selected = (idx >= 0 && idx < options.length) ? getOptionText(options[idx]) : ''

  return (
    <div {...props}>
      <div className="dropdownRoot" >
        <div className="dropdownCurrent">
          <div>{selected}</div>
          <div className="dropdownMark">▼</div>
        </div>
        <div className="dropdownContent">
          { optionsElements }
        </div>
      </div>
    </div>
  )
}

export default Dropdown