/* eslint-disable import/first */
import { useState, useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import './MaterialList.css'

import MaterialView from './MaterialView'

const MaterialsList = ( { viewer, filter, searchTerm, modelId, setLoading }) => {
  const [displayMaterials, setDisplayMaterials] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const itemsPerPage = 50

  useEffect(() => {
    const fetch = async () => {
      const options = { filter, searchTerm, modelId }
      const materials = await viewer.fetchMaterialsList(options)
      setDisplayMaterials(materials)
      const totalPages = Math.ceil(materials.length / itemsPerPage)
      setCurrentPage(Math.max(0, Math.min(currentPage, totalPages - 1)))
    }
    fetch()
  }, [viewer, filter, searchTerm, modelId, currentPage])

  const pageRange = 5
  const totalPages = Math.ceil(displayMaterials.length / itemsPerPage)
  const pageCount = Math.min(pageRange, totalPages)
  const startPage = Math.max(0, currentPage - Math.floor(pageRange / 2))
  const endPage = Math.min(currentPage + Math.floor(pageRange / 2), pageCount-1)
  const pages = []

  for (let i = startPage; i <= endPage; i++) {
    const className = i === currentPage ? 'paginationPageActive' : 'paginationPage'
    pages.push(
      <div className={className} key={`page-${i}`} onClick={() => setPage(i)}>{ i+1 }</div>
    )
  }

  const startItem = currentPage * itemsPerPage
  const endItem = startItem + itemsPerPage
  const materialViews = displayMaterials
  .slice(startItem, endItem)
  .map(mtl => 
    <MaterialView
      viewer={viewer}
      setLoading={setLoading}
      material={mtl}
      key={mtl.id}
    />
  )

  const nextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages - 1))
  }

  const prevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 0))
  }

  const firstPage = () => {
    setCurrentPage(0)
  }

  const lastPage = () => {
    setCurrentPage(totalPages - 1)
  }

  const setPage = (page) => {
    setCurrentPage(page)
  }

  return (
    <>
      <div className="paginationContainer">
        <div className="paginationRoot">
          <div className="paginationPage" onClick={firstPage}>«</div>
          <div className="paginationPage" onClick={prevPage}>⟨</div>
          { pages }
          <div className="paginationPage" onClick={nextPage}>⟩</div>
          <div className="paginationPage" onClick={lastPage}>»</div>
        </div>
      </div>
      <Scrollbars autoHide style={{ width: '100%', height: '45vh' }}>
        <div className="materialListRoot">
          { materialViews }
        </div>
      </Scrollbars>
    </>
  )
}

export default MaterialsList
/* eslint-enable import/first */