import './App.css';

import { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as EthanAllenViewer from 'ethan-allen-webgl'

import config from './config'
import MaterialsList from './components/MaterialsList'
import ControlPanel from './components/ControlPanel'

const Loader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="loader"
      width="211px"
      height="211px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#93dbe9" strokeWidth="1" r="10" strokeDasharray="47 17">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>
  )
}

const App = () => {
  const [initializing, setInitializing] = useState(true)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [currentModelIdx, setCurrentModelIdx] = useState(0)
  const [currentModelId, setCurrentModelId] = useState('')

  const viewerRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const viewerOptions = {
        dataUrl: config.dataUrl,
        modelsUrlRoot: config.modelsUrlRoot,
        texturesUrlRoot: config.texturesUrlRoot,
        environmentUrl: config.environmentUrl,
      }
      const viewer = await EthanAllenViewer.initialize(canvasRef.current, viewerOptions)
      viewerRef.current = viewer
      setLoading(false)
      setInitializing(false)
    }
    init()
  }, [canvasRef])

  useEffect(() => {
    const loadModel = async () => {
      const modelList = await viewerRef.current.fetchModelsList()
      const promise = viewerRef.current.getCurrentModelOperation()
      if (promise) {
          // return modelIDx:
          const modelIdx = modelList.findIndex(model => model.id === currentModelId)
          if (modelIdx >= 0) {
            setCurrentModelIdx(modelIdx)
          }
        return
      }

      setLoading(true)
      if (currentModelIdx >= 0 && currentModelIdx < modelList.length) {
        const modelId = modelList[currentModelIdx].id
        if (modelId !== currentModelId) {
          try {
            await viewerRef.current.loadModel(modelId)
            setCurrentModelId(modelId)
          } catch (err) {
            if (err instanceof String && err.includes('is in progress')) {
              toast.warn('Example warning: operation in progress', {
                hideProgressBar: true,
              })
            } else {
              toast.error(`Error while loading the model: ${err}`, {
                hideProgressBar: true,
              })
            }
            console.error(err)
          }
        }
      }
      setLoading(false)
    }

    if (initializing) {
      return
    }
    loadModel()
  }, [viewerRef, initializing, currentModelIdx, currentModelId])

  return (
    <div className="App">
      <Scrollbars autoHide style={{ width: '100vw', height: '100vh' }}>
        <div className="mainContainer">
          <div className="canvasContainer">
            <canvas className="mainCanvas" ref={canvasRef} />
            { loading && <Loader />}
          </div>
          { !initializing && (
            <div className="controlPanelContainer">
              <ControlPanel
                viewer={viewerRef.current}
                applyFilter={setFilter}
                applySearchTerm={setSearchTerm}
                currentModel={currentModelIdx}
                applyModel={setCurrentModelIdx}
              />
            </div>
          )}
        </div>
        { !initializing && (
          <div className="materialsContainer">
            <MaterialsList
              viewer={viewerRef.current}
              setLoading={ setLoading }
              filter={filter}
              searchTerm={searchTerm}
              modelId={currentModelId}
            />
          </div>
        )}
      </Scrollbars>
      <ToastContainer />
    </div>
  )
}

export default App