import './Checkbox.css'

const Checkbox = ( { text, checked, onChange, ...props }) => {
  return (
    <div {...props}>
      <div className="cbContainer" onClick={onChange} >
        <input type="checkbox" checked={checked} onChange={()=>{}}/>
        <div className="cbLabel">{text}</div>
      </div>
    </div>
  )
}

export default Checkbox