import { useState, useEffect } from 'react'
import { parse, format} from 'date-fns'
import { toast } from 'react-toastify'

import './MaterialView.css'

const MaterialView = ({ material, viewer, setLoading }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('')

  useEffect(() => {
    const fetch = async () => {
      if (!viewer || !material) {
        return null
      }
      const url = await viewer.getTextureUrl(material.thumbnail)
      setThumbnailUrl(url)
    }
    fetch()
  }, [viewer, material])

  const onMaterialClick = async () => {
    if (!viewer || !material) {
      return
    }
    const promise = viewer.getCurrentModelOperation()
    if (promise) {
      return
    }

    setLoading(true)
    try {
      await viewer.applyMaterial(material.id)
      setLoading(false)
    } catch (err) {
      if (err.includes('is in progress')) {
        toast.warn('Example warning: operation in progress', {
          hideProgressBar: true,
        })
      } else {
        toast.error(`Error while applying the material: ${err}`, {
          hideProgressBar: true,
        })
      }
      console.error(err)
    }
  }

  const style = {
    backgroundImage: `url(${thumbnailUrl})`,
    backgroundSize: 'contain',
  }

  let cutCodeStr = `(${material.fabricCutCode || '-'}`
  if (material.fabricMatchCode) {
    cutCodeStr += `-${material.fabricMatchCode})`
  } else {
    cutCodeStr += ')'
  }

  const mode = material.metal !== undefined ? 'Metal/Rough' : 'Spec/Gloss'
  const flipTxt = `fabricFlipPlaid: ${material.fabricFlipPlaid.toString()}`
  
  const date = parse(material.introDate, 'dd/MM/yyyy', new Date())
  const dateStr = format(date, 'MMMM dd, yyyy')

  return (
    <div className="materialViewContainer" onClick={onMaterialClick}>
      <div className="materialViewRoot">
        <div className="materialViewInner">
          <div className="materialTitle"> {`${material.id}, ${material.displayName || '-'} ${cutCodeStr}`} </div>
            <div className="materialType"> {`${material.fabricType || '-'}, ${mode}`} </div>
            <div className="materialType"> {flipTxt} </div>
            <div className="materialType"> {dateStr} </div>
            <div
              className="materialThumbnail"
              style = { style }
            />
        </div>
      </div>
    </div>
  )
}

export default MaterialView