import { useState, useEffect } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import config from '../config'

import './ControlPanel.css'
import Checkbox from './Checkbox'
import Dropdown from './Dropdown'

const ControlPanel = ( { viewer, applyFilter, applySearchTerm, currentModel, applyModel }) => {
  const [dimensionsVisible, setDimensionsVisible] = useState(viewer.isDimensionsVisible())
  const [usePoTMaps, setUsePoTMaps] = useState(false)

  const [cutCodeOptions, setCutCodeOptions] = useState([])
  const [matchCodeOptions, setMatchCodeOptions] = useState([])
  const [fabricTypeOptions, setFabricTypeOptions] = useState([])
  const [fabricFamilyOptions, setFabricFamilyOptions] = useState([])
  const [fabricPatternOptions, setFabricPatternOptions] = useState([])
  const [fabricColorOptions, setFabricColorOptions] = useState([])
  const [fabricFlipOptions, setFabricFlipOptions] = useState([])

  const [selectedCutCode, setSelectedCutCode] = useState(0)
  const [selectedMatchCode, setSelectedMatchCode] = useState(0)
  const [selectedFabricType, setSelectedFabricType] = useState(0)
  const [selectedFabricFamily, setSelectedFabricFamily] = useState(0)
  const [selectedFabricPattern, setSelectedFabricPattern] = useState(0)
  const [selectedFabricColor, setSelectedFabricColor] = useState(0)
  const [selectedFlip, setSelectedFlip] = useState(0)

  const [modelOptions, setModelOptions] = useState([])

  const [searchTerm, setSearchTerm] = useState('')

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useEffect(() => {
    const init = async () => {
      viewer.collectAllMaterialValues('fabricCutCode')
        .then((opts) => {opts.unshift('-'); setCutCodeOptions(opts)})
      viewer.collectAllMaterialValues('fabricMatchCode')
        .then((opts) => {opts.unshift('-'); setMatchCodeOptions(opts)})
      viewer.collectAllMaterialValues('fabricType')
        .then((opts) => {opts.unshift('-'); setFabricTypeOptions(opts)})
      viewer.collectAllMaterialValues('fabricFamily')
        .then((opts) => {opts.unshift('-'); setFabricFamilyOptions(opts)})
      viewer.collectAllMaterialValues('fabricPattern')
        .then((opts) => {opts.unshift('-'); setFabricPatternOptions(opts)})
      viewer.collectAllMaterialValues('fabricColor')
        .then((opts) => {opts.unshift('-'); setFabricColorOptions(opts)})
      viewer.collectAllMaterialValues('fabricFlipPlaid')
        .then((opts) => {opts.unshift('-'); setFabricFlipOptions(opts)})

      viewer.fetchModelsList('fabricColor')
        .then((models) => {setModelOptions(models.map(model => model.displayName))})
    }
    init()
  }, [viewer])

  useEffect(() => {
    const filter = {}
    if (selectedCutCode > 0) {
      filter.fabricCutCode = cutCodeOptions[selectedCutCode]
    }
    if (selectedMatchCode > 0) {
      filter.fabricMatchCode = matchCodeOptions[selectedMatchCode]
    }
    if (selectedFabricType > 0) {
      filter.fabricType = fabricTypeOptions[selectedFabricType]
    }
    if (selectedFabricFamily > 0) {
      filter.fabricFamily = fabricFamilyOptions[selectedFabricFamily]
    }
    if (selectedFabricPattern > 0) {
      filter.fabricPattern = fabricPatternOptions[selectedFabricPattern]
    }
    if (selectedFabricColor > 0) {
      filter.fabricColor = fabricColorOptions[selectedFabricColor]
    }
    if (selectedFlip > 0) {
      filter.fabricFlipPlaid = fabricFlipOptions[selectedFlip]
    }
    filter.fromDate = fromDate
    filter.toDate = toDate
    if (applyFilter) {
      applyFilter(filter)
    }
  }, [applyFilter, selectedCutCode, selectedMatchCode, selectedFabricType, selectedFabricFamily,
    selectedFabricPattern, selectedFabricColor, selectedFlip,
    cutCodeOptions, matchCodeOptions, fabricTypeOptions, fabricFamilyOptions, fabricPatternOptions,
    fabricColorOptions, fabricFlipOptions, fromDate, toDate])

  useEffect(() => {
    viewer.setDimensionsVisible(dimensionsVisible)
  }, [viewer, dimensionsVisible])

  useEffect(() => {
    applySearchTerm(searchTerm)
  }, [searchTerm, applySearchTerm])

  useEffect(() => {
    if (viewer) {
      viewer.dataService.texturesUrlRoot = usePoTMaps
        ? config.texturesUrlRootPoT
        : config.texturesUrlRoot

      const container = viewer.model.loadedContainers.get(viewer.model.currentModelCode)
      viewer.resolveMaterialMapUrls(viewer.model.materialInfo)
        .then(() => viewer.model.applyMaterialInternal(viewer.model.materialInfo, container))
    }
  }, [viewer, usePoTMaps])

  return (
    <div className="controlPanelRoot">
      <div className="settingRow">
        <div className="settingLabel">Model:</div>
        <Dropdown
          className="filterDropdown"
          options={modelOptions}
          selectedIdx={currentModel}
          onChange={applyModel}
        />
      </div>
      <div className="settingRow">
        <Checkbox
          text={'Dimensions'}
          checked={dimensionsVisible}
          onChange={() => setDimensionsVisible(!dimensionsVisible)}
        />
      </div>
      <div className="settingRow">
        <div className="filterRow">
          <div className="settingLabel">{'Search: '}</div>
          <input type="text" onInput={e => setSearchTerm(e.target.value)}/>
        </div>
      </div>
      <div className="filterRoot">
        <div>Filter:</div>
          <div className="filterRow">
            <div className="settingLabel">{'Cut Code'}</div>
            <Dropdown
              className="filterDropdown"
              options={cutCodeOptions}
              selectedIdx={selectedCutCode}
              onChange={setSelectedCutCode}
            />
          </div>
          <div className="filterRow">
            <div className="settingLabel">{'Match Code'}</div>
            <Dropdown
              className="filterDropdown"
              options={matchCodeOptions}
              selectedIdx={selectedMatchCode}
              onChange={setSelectedMatchCode}
            />
          </div>
          <div className="filterRow">
            <div className="settingLabel">{'Fabric Type'}</div>
            <Dropdown
              className="filterDropdown"
              options={fabricTypeOptions}
              selectedIdx={selectedFabricType}
              onChange={setSelectedFabricType}
            />
          </div>
          <div className="filterRow">
            <div className="settingLabel">{'Fabric Family'}</div>
            <Dropdown
              className="filterDropdown"
              options={fabricFamilyOptions}
              selectedIdx={selectedFabricFamily}
              onChange={setSelectedFabricFamily}
            />
          </div>
          <div className="filterRow">
            <div className="settingLabel">{'Fabric Pattern'}</div>
            <Dropdown
              className="filterDropdown"
              options={fabricPatternOptions}
              selectedIdx={selectedFabricPattern}
              onChange={setSelectedFabricPattern}
            />
          </div>
          <div className="filterRow">
            <div className="settingLabel">{'Fabric Color'}</div>
            <Dropdown
              className="filterDropdown"
              options={fabricColorOptions}
              selectedIdx={selectedFabricColor}
              onChange={setSelectedFabricColor}
            />
          </div>
          <div className="filterRow">
            <div className="settingLabel">{'Flip Plaid'}</div>
            <Dropdown
              className="filterDropdown"
              options={fabricFlipOptions.map(o => o.toString())}
              selectedIdx={selectedFlip}
              onChange={setSelectedFlip}
            />
          </div>
          <div className="filterRow">
          <div className="settingLabel">Intro date:</div>
            <div className="dateGroup">
              <div className="settingLabel">From:</div>
              <DatePicker
                className="datePick"
                selected={fromDate}
                showYearDropdown
                showMonthDropdown
                onChange={date => setFromDate(date)}
              />
            </div>
            <div className="dateGroup">
              <div className="settingLabel">To:</div>
              <DatePicker
                className="datePick"
                selected={toDate}
                showYearDropdown
                showMonthDropdown
                onChange={date => setToDate(date)}
              />
            </div>
          </div>
      </div>
    </div>
  )
}

export default ControlPanel